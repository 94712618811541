import React, { useState } from 'react';
import { Input } from 'react-rainbow-components';
import { showAppMessage } from '@rainbow-modules/app';
import signInWithEmailAndPassword from '../../services/signInWithEmailAndPassword';
import {
    Container, CardContent, InputIcon, StyledButton, Title, Logo, Footer,
} from './styled';
import Lock from '../../components/icons/Lock';

function getYear() {
    return new Date().getFullYear();
}

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setLoading] = useState(false);

    const signIn = async (event) => {
        event.preventDefault();
        if (email && password) {
            setLoading(true);
            await signInWithEmailAndPassword(email, password);
            setLoading(false);
        } else {
            showAppMessage({
                message: 'Uh-oh!, looks like you have forgotten your email or password.',
                timeout: 8000,
            });
        }
    };

    return (
        <Container>
            <Logo />
            <Title>
                Sign in
            </Title>
            <CardContent>
                <form noValidate onSubmit={signIn}>
                    <Input
                        type="email"
                        label="Email"
                        placeholder="Enter your Email Address"
                        onChange={(event) => setEmail(event.target.value)}
                        icon={<InputIcon />}
                        className="rainbow-m-bottom_x-large"
                        required
                        autoComplete="off"
                    />
                    <Input
                        type="password"
                        label="Password"
                        placeholder="Enter your password"
                        onChange={(event) => setPassword(event.target.value)}
                        icon={<InputIcon as={Lock} />}
                        className="rainbow-m-vertical_large"
                        required
                        autoComplete="off"
                    />
                    <StyledButton
                        label="Sign In"
                        type="submit"
                        isLoading={isLoading}
                        variant="brand"
                    />
                </form>
            </CardContent>
            <Footer>
                {`© ${getYear()} EverTransit LLC`}
            </Footer>
        </Container>
    );
};

export default SignIn;
