import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RainbowFirebaseApp } from '@rainbow-modules/app';
import { WhenAuthenticated, WhenNoAuthenticated } from '@rainbow-modules/auth';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import { Spinner, Modal, Button } from 'react-rainbow-components';
import styled from 'styled-components';
import startApplication from './actions/startApplication';
import Billing from './components/icons/Billing';
import Logo from './components/icons/EvertransitLogo';
import theme from './theme';
import SignIn from './pages/signin';
import firebase from './firebase';
import makeRedirection from './services/makeRedirection';

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: black;
    z-index: 1000000;
`;

const AppLogo = styled(Logo)`
    width: 36px;
    height: 36px;
`;

const StyledBilling = styled(Billing)`
    width: 68px;
    height: 68px;
`;

function useRedirectType() {
    const redirectTo = new URLSearchParams(useLocation().search).get('redirectTo');
    const redirectToCallable = new URLSearchParams(useLocation().search).get('redirectToCallable');
    if (redirectTo) {
        return 'link';
    }
    if (redirectToCallable) {
        return 'callable';
    }
    return undefined;
}

function useRedirectToQuery() {
    const redirectTo = new URLSearchParams(useLocation().search).get('redirectTo');
    const redirectToCallable = new URLSearchParams(useLocation().search).get('redirectToCallable');
    if (redirectTo) {
        return redirectTo;
    }
    return redirectToCallable;
}

const RedirectTo = () => {
    const redirectTo = useRedirectToQuery();
    const redirectType = useRedirectType();
    makeRedirection(redirectTo, redirectType);
    return null;
};

const Routes = () => {
    const [isInitializing, setIsInitializing] = useState(true);
    const [isOpen, setOpen] = useState(false);
    const redirectTo = useRedirectToQuery();
    const redirectType = useRedirectType();
    const { search } = useLocation();
    const authRedirect = {
        pathname: '/signin',
        search,
    };
    const noAuthRedirect = {
        pathname: '/',
        search,
    };

    const returnUrl = new URLSearchParams(useLocation().search).get('returnUrl');

    useEffect(() => {
        (async () => {
            const started = await startApplication(redirectTo, redirectType, () => setOpen(true));
            if (!started) {
                setIsInitializing(false);
            }
        })();
    }, [redirectTo, redirectType]);

    return (
        <>
            <RenderIf isTrue={!isInitializing}>
                <WhenAuthenticated path="/" redirect={authRedirect} component={RedirectTo} />
                <WhenNoAuthenticated path="/signin" redirect={noAuthRedirect} component={SignIn} />
            </RenderIf>
            <RenderIf isTrue={isInitializing}>
                <SpinnerContainer>
                    <Spinner type="arc" size="x-large" variant="brand">
                        <AppLogo />
                    </Spinner>
                </SpinnerContainer>
            </RenderIf>
            <Modal isOpen={isOpen} hideCloseButton>
                <div className="rainbow-flex rainbow-flex_column rainbow-justify_center rainbow-align_center">
                    <StyledBilling className="rainbow-m-top_xx-large rainbow-m-bottom_medium" />
                    <div className="rainbow-font-size-heading_medium rainbow-m-bottom_small">
                        You don&apos;t have access to billing
                    </div>
                    <div className="rainbow-font-size-text_large">
                        The billing portal is only accessible for subscribed fleets
                    </div>
                    <Button
                        className="rainbow-m-top_large rainbow-m-bottom_large"
                        label="Go Back"
                        variant="brand"
                        onClick={() => window.location.assign(returnUrl)}
                    />
                </div>
            </Modal>
        </>
    );
};

const App = () => (
    <RainbowFirebaseApp
        app={firebase}
        theme={theme}
        locale="en"
        spinner={(
            <Spinner type="arc" size="x-large" variant="brand">
                <AppLogo />
            </Spinner>
        )}
    >
        <Routes />
    </RainbowFirebaseApp>
);

export default App;
