import React from 'react';
import PropTypes from 'prop-types';

export default function Billing(props) {
    const { className, style } = props;
    return (

        <svg className={className} style={style} width="512px" height="512px" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>billing</title>
            <g id="test" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="billing" fillRule="nonzero">
                    <path d="M335.448276,0 C432.955101,0 512,79.0448993 512,176.551724 L512,335.448276 C512,432.955101 432.955101,512 335.448276,512 L176.551724,512 C79.0448993,512 0,432.955101 0,335.448276 L0,176.551724 C0,79.0448993 79.0448993,0 176.551724,0 L335.448276,0 Z" id="path-2" fill="#1DAB8F" />
                    <path d="M256,106 C173.150442,106 106,173.156969 106,256 C106,338.843031 173.150442,406 256,406 C338.849558,406 406,338.836503 406,256 C406,173.163497 338.843031,106 256,106 Z M265.08003,322.817529 L265.08003,340.181209 C265.08003,342.890204 262.971583,344.861569 260.25606,344.861569 L248.682449,344.861569 C245.966926,344.861569 243.780147,342.890204 243.780147,340.181209 L243.780147,324.345011 C233.126942,323.861961 222.382349,321.590322 214.248836,318.496192 C209.842639,316.825101 207.388224,312.105575 208.556682,307.536185 L210.443187,300.185996 C211.102485,297.607555 212.825797,295.453414 215.182297,294.24579 C217.538796,293.018582 220.306541,292.894556 222.780539,293.841072 C230.528918,296.837286 239.680752,299.017538 249.452718,299.017538 C261.894512,299.017538 270.41316,294.213151 270.41316,285.47909 C270.41316,277.182384 263.428522,271.940641 247.265938,266.476957 C223.903303,258.617607 207.903912,247.703294 207.903912,226.52091 C207.903912,207.309892 221.50111,192.243962 244.746247,187.654989 L244.746247,171.818791 C244.746247,169.109796 247.06358,166.681492 249.779103,166.681492 L261.352713,166.681492 C264.068236,166.681492 266.059184,169.109796 266.059184,171.818791 L266.059184,186.12098 C276.222812,186.558336 283.559946,188.066234 289.695983,190.06371 C294.337177,191.578137 297.078811,196.441272 295.858131,201.180382 L294.200096,207.760303 C293.553854,210.266939 291.895818,212.394969 289.624179,213.622177 C287.352539,214.849384 284.669655,215.071326 282.21524,214.229253 C276.640585,212.316637 269.368728,210.580269 260.151617,210.580269 C245.960399,210.580269 241.371426,216.696723 241.371426,222.813177 C241.371426,230.019757 249.015362,234.602202 267.573611,241.593368 C293.553854,250.764785 303.991645,262.775752 303.991645,282.417599 C303.985117,301.850559 290.257365,318.457026 265.08003,322.817529 Z" id="Shape" fill="#FFFFFF" />
                </g>
            </g>
        </svg>
    );
}

Billing.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

Billing.defaultProps = {
    style: undefined,
    className: undefined,
};
