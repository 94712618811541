export default function getAllQueryParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const entries = urlParams.entries();
    return Array.from(entries).reduce((acc, entry) => {
        const [key, value] = entry;
        return {
            ...acc,
            [key]: value,
        };
    }, {});
}
