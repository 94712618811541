import firebase from '../firebase';
import getAllQueryParams from './getAllQueryParams';

export default async function makeRedirection(redirectTo, redirectType = 'link', onError = () => {}) {
    if (redirectType === 'link') {
        console.log('Redirecting to link: ', redirectTo);
        return window.location.assign(redirectTo);
    }
    if (redirectType === 'callable') {
        const data = getAllQueryParams();
        console.log('Redirecting to callable: ', redirectTo);
        try {
            const response = await firebase.functions().httpsCallable(redirectTo)(data);
            const redirectToLink = response && response.data;
            if (redirectToLink) {
                return window.location.assign(redirectToLink);
            }
        } catch (error) {
            console.error(error);
        }
        return onError();
    }
    return undefined;
}
