import getFirebaseCookieToken from '../services/getFirebaseCookieToken';
import signInWithCustomToken from '../services/signInWithCustomToken';
import firebase from '../firebase';
import getEvertransitToken from '../services/getEvertransitToken';
import authWithEtToken from '../services/authWithEtToken';
import setFirebaseCookieToken from '../services/setFirebaseCookieToken';
import makeRedirection from '../services/makeRedirection';

const tryFirebaseLogin = async () => {
    const customToken = getFirebaseCookieToken();
    if (customToken) {
        console.log('Logging with firebase custom token');
        try {
            const userCredentials = await signInWithCustomToken(customToken);
            if (userCredentials.user) {
                return userCredentials;
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }
    return undefined;
};

const tryEvertransitTokenLogin = async () => {
    const evertransitToken = getEvertransitToken();
    if (evertransitToken) {
        console.log('Logging with evertransit token');
        try {
            const customToken = await authWithEtToken(evertransitToken);
            if (customToken) {
                const userCredentials = await signInWithCustomToken(customToken);
                if (userCredentials.user) {
                    setFirebaseCookieToken(customToken);
                    return userCredentials;
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    return undefined;
};

const startApplication = async (redirectTo, redirectType, onError) => {
    let userCredentials = await tryFirebaseLogin();
    if (!userCredentials) {
        userCredentials = await tryEvertransitTokenLogin();
    }
    if (userCredentials && redirectTo) {
        makeRedirection(redirectTo, redirectType, onError);
        return true;
    }
    await firebase.auth().signOut();
    return false;
};

export default startApplication;
