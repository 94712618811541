import Cookies from 'js-cookie';

export default function getEvertransitCookie() {
    const etCookie = Cookies.get(process.env.REACT_APP_ET_COOKIE_NAME);
    try {
        return etCookie && JSON.parse(etCookie);
    } catch (error) {
        console.error(error);
        return undefined;
    }
}
