import { showAppMessage } from '@rainbow-modules/app';
import makeRequest from './makeRequest';
import signInWithCustomToken from './signInWithCustomToken';
import setFirebaseCookieToken from './setFirebaseCookieToken';

const url = `${process.env.REACT_APP_BASE_URL}/auth`;

const signInWithEmailAndPassword = async (email, password) => {
    const response = await makeRequest({
        url,
        method: 'POST',
        data: { email, password },
    });
    switch (response.status) {
        case 400:
            return showAppMessage({
                message: 'Uh-oh!, looks like you have forgotten your email or password.',
                timeout: 8000,
            });
        case 403:
            return showAppMessage({
                message: 'Uh-oh!, looks like your email or password is invalid.',
                timeout: 8000,
            });
        case 200:
            return (async () => {
                const { token: customToken } = await response.json();
                await signInWithCustomToken(customToken);
                setFirebaseCookieToken(customToken);
            })();
        default:
            return showAppMessage({
                message: 'Uh-oh!, looks like you have forgotten your email or password.',
                timeout: 8000,
            });
    }
};

export default signInWithEmailAndPassword;
