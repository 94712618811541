import makeRequest from './makeRequest';

const url = `${process.env.REACT_APP_BASE_URL}/auth/et/token`;

export default async function authWithEtToken(token) {
    const response = await makeRequest({
        url,
        method: 'POST',
        data: { token },
    });
    const responseJson = await response.json();
    if (responseJson) { return responseJson.token; }
    return null;
}
